/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f7f8fa 0% 0% no-repeat padding-box;
  opacity: 1;
}

ul {
  padding-left: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  &:disabled {
    cursor: not-allowed !important;
  }
}

.hide-scroll {
  overflow: hidden;
}

.flex--box {
  display: flex;
  flex-direction: column;
}

.back {
  width: 50px;
  height: 50px;
  background: #354052 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.flex-row-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.flex-col-space-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.mat-form-field {
  width: 100%;
  margin-bottom: 10px;
}

.mat-dialog-actions[align="end"] {
  margin-bottom: 5px;
}

.sub-title {
  text-align: left;
  font: normal normal normal 14px/21px "Poppins";
  letter-spacing: 0px;
  color: #b0b3ba;
  text-transform: uppercase;
  opacity: 1;
}

.btn {
  width: 132px;
  height: 51px;
  border-radius: 26px !important;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font: normal normal normal 16px/25px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none;

  &:disabled {
    cursor: not-allowed !important;
  }

  &.success {
    background: #25db72;
  }
  &.primary-button {
    background: #2021fe;
  }
  &.decline-button {
    background: #ff5668;
  }
  &.outline-button {
    border: 1px solid #707070;
    color: #707070;
  }
}

.primary-button {
  background: #2021fe 0% 0% no-repeat padding-box;
  width: 132px;
  height: 51px;
  border-radius: 26px !important;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font: normal normal normal 16px/25px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none;

  &:disabled {
    cursor: not-allowed;
  }
}

.decline-button {
  background: #ff5668 0% 0% no-repeat padding-box;
  width: 132px;
  height: 51px;
  border-radius: 26px !important;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font: normal normal normal 16px/25px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  border: none;
}

.outline-button {
  width: 132px;
  height: 51px;
  border: 1px solid #707070;
  border-radius: 26px !important;
  font: normal normal 600 14px/21px "Poppins";
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  background: transparent;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

div.pac-container {
  position: absolute;
  top: 100px;
}

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 30px;
  left: 50%;
  right: 50%;

  img {
    width: 230px;
    height: 150px;
  }

  .info {
    h3 {
      font: normal normal 600 24px/28px "Poppins";
      text-align: center;
    }
    p {
      font: normal normal 16px/21px "Poppins";
      text-align: center;
    }
  }
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select {
  user-select: none;
}

.confirm-modal-body {
  padding: 20px 20px 5px;
}

.confirm-modal-body h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

::-webkit-scrollbar {
  width: 8px;
  transition: 500ms width;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 8px;

  &:hover {
    background: #cccccc;
  }
}

.audit-page {
  span.ok {
    color: rgb(2, 122, 72);
    background-color: rgba(2, 122, 72, 0.2);
  }

  span.forbidden {
    color: rgb(181, 71, 8);
    background-color: rgba(181, 71, 8, 0.2);
  }
}

.message-block .error-message {
  display: none;
}
.message-block .error-message:first-child {
  display: block;
}

.request-details {
  &__item {
    font-family: "Poppins";
    border-bottom: 1px solid rgba(112, 112, 112, 0.5);
    padding-bottom: 10px;
    margin-bottom: 20px;
    &__title {
      color: rgba(105, 105, 105, 0.5);

      font-size: 14px;
      font-weight: 400;
      line-height: 15px; /* 107.143% */
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &__value {
      color: #696969;
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
    }
  }
}
